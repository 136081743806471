<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
            <em>当前位置：</em>
          <a href="javascript:;" @click="$router.back()">结算管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">课程结算</a>
          <i>></i>
          <a href="javascript:;"  class="cur-a">使用详情</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
           
             <div title="课件名称" class="searchboxItem">
              <span class="itemLabel">课件名称:</span>
              <el-input v-model="kpointName" type="text" size="small" placeholder="请输入课件名称" clearable/>
            </div>
            <div class="df">
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
            </div>
            </div>
        </div>
         <div class="lessonNum" style="padding:10px">
            <span style="padding: 0 15px">实际使用课件数量：{{useKpointNum || 0}}个</span>
            <span>实际使用课件学时：{{useLessNum || 0}}学时</span>
          </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
             <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
            <el-table-column label="序号" align="center" type="index" :index="indexMethod"/>
              <el-table-column label="课件名称" align="center"  show-overflow-tooltip prop="kpointName" width='400px'/>
              <el-table-column label="课件来源" align="center"  show-overflow-tooltip prop="kpointOrigin" width='400px'/>
              <el-table-column label="课件学时" align="center"  show-overflow-tooltip prop="kpointLessonNum"/>
              <el-table-column label="完成人数" align="center"  show-overflow-tooltip prop="completeNum">
                   <template slot-scope="scope">
                      <span>{{scope.row.completeNum || 0}}</span>
                  </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData"/>
      </div>
    </div>
  </div>
</template>
<script>
import PageNum from "@/components/PageNum.vue";
import Empty from "@/components/Empty.vue";
import List from "@/mixins/List";
import moment from "moment";
export default {
    name: "evaluate/CoursesettlementDatails",
    components: {
        Empty,
        PageNum
    },
    data() {
        return {
        useKpointNum:this.$route.query.useKpointNum,
        useLessNum:this.$route.query.useLessNum,
        courseName:'',
        kpointName:'',
        kpointOrigin:'',
        courseLessNum:'',
        studyNum:'',
        completeNum:'',
        };
    },
    mixins: [List],
    created: function() {},
    methods: {
        getData(pageNum = 1) {
            const params = {
                pageNum: pageNum,
                pageSize: this.pageSize,
                projectId: this.$route.query.projectId,
                kpointName:this.kpointName,
                projectCourseId:this.$route.query.projectCourseId
            };
            if(this.kpointName) {
                params.kpointName = this.kpointName
            }
            this.doFetch({
                url: "/run/project/queryUseDetailList",
                params,
                pageNum,
            });
            this.getCount()
        },
        /*  */
        getCount() {
            const params = {
                projectId: this.$route.query.projectId
            };
            if(this.courseName) {
                params.courseName = this.courseName
            }
            this.$post('/run/project/courseTotalPeriod',params).then(ret =>{
                let retData = ret.data || {};
                this.courseLessNum = retData.courseLessNum;
                this.studyNum = retData.studyNum;
                this.completeNum = retData.completeNum;
            }).catch(err => {
                return;
            })
        }
    }
};
</script>

<style lang="less" scoped>
.classDetail{
  .searchbox{
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
  }
  .itemLabel{
    margin: 0 20px;
  }
  .df{
    margin-left: 20px;
  }
}
</style>
